import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PagedModel } from "app/models/base-models";
import { UserDetails } from "app/models/user/user-details";
import { UserListFilter } from "app/models/user/user-list-filter";
import { UserRole } from "app/models/user/user-role";
import { environment } from "environments/environment";
import { BehaviorSubject, map, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) { }

    user$ = new BehaviorSubject<UserDetails | undefined>(undefined); // single

    getUserDetailsById(id: string): Observable<UserDetails> {
        return this.http
            .get<UserDetails>(`${environment.apiUrl}/users/${id}`)
            .pipe(
                map((res: UserDetails) => {
                    this.user$.next(res);
                    return res;
                })
            );
    }

    getPaginatedUsers(
        page: number,
        pageSize: number,
        sortKey: string,
        sortOrder: string,
        filters: UserListFilter): Observable<PagedModel> {

        let httpParams = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString())
            .set('sortKey', sortKey || '')
            .set('sortOrder', sortOrder);

        if (filters.clientId) {
            httpParams = httpParams.set('clientId', filters.clientId);
        }
        if (filters.userId) {
            httpParams = httpParams.set('userId', filters.userId);
        }
        if (filters.username) {
            httpParams = httpParams.set('username', filters.username.trim());
        }
        if (filters.role && filters.role != "All") {
            httpParams = httpParams.set('roleCode', filters.role);
        }
        if (filters.firstName) {
            httpParams = httpParams.set('firstName', filters.firstName.trim());
        }
        if (filters.surname) {
            httpParams = httpParams.set('surname', filters.surname.trim());
        }
        if (filters.email) {
            httpParams = httpParams.set('email', filters.email.trim());
        }
        if (filters.mobile) {
            httpParams = httpParams.set('mobile', filters.mobile.trim());
        }

        return this.http.get(`${environment.apiUrl}/users?`, {
            params: httpParams
        }).pipe(
            map(res => {
                return new PagedModel(res["firstItemOnPage"],
                    res["lastItemOnPage"],
                    res["totalItemCount"],
                    res["items"]);
            })
        );
    }

    getUserRoles(userApiId: string):  Observable<UserRole[]>{
        return this.http.get<UserRole[]>(`${environment.apiUrl}/users/${userApiId}/roles`);
    }
}